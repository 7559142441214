import { PermissionExpression, roles, scopes } from 'SRC/hooks/useIdentity';

const RECONCILIATION_WRITE_PERMISSION: PermissionExpression =
  scopes.RECONCILIATION_WRITE;

export const page: PermissionExpression = ({ is }) =>
  is(roles.READONLY_NON_PII) || is(roles.READONLY) || is(roles.SUPPORT_AGENT);

export const exportBatch: PermissionExpression = scopes.PII_READ;
export const reprocessBatch: PermissionExpression =
  RECONCILIATION_WRITE_PERMISSION;
export const addConnection: PermissionExpression =
  RECONCILIATION_WRITE_PERMISSION;
