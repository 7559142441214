/* eslint-disable @typescript-eslint/naming-convention */
type CamelCase<S extends string> =
  S extends `${infer P1}-${infer P2}${infer P3}`
    ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
    : Lowercase<S>;

type KeysToCamelCase<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends Record<string, unknown>
    ? KeysToCamelCase<T[K]>
    : T[K];
};

type MakeUnionWithUndefined<T> = {
  [K in keyof T]: T[K] extends string ? string : T[K] | undefined;
};

/***
 * Enter keys as they are defined in the LaunchDarkly dashboard. We assume that the key is a kebab-case.
 * The LaunchDarkly SDK will transform the key to camelCase. But we need an original key for default values.
 */
export const defaultFeatureFlags = {
  'application-connection-filter': false,
  'authorize-payment-smart-routing-beta': false,
  'checkout-builder-modules': false,
  'hide-unready-apms': {
    amps: [],
  },
  'internal-tools': false,
  'new-refund-limits': false,
  'obs-one-snapshot': false,
  'obs-recommended-monitors': false,
  'observability-payments-write-scope': false,
  'payments-details-new': false,
  'primer-etl': false,
  reconciliation: false,
  'apm-account-override': false,
  'workflow-publish-payments': false,
  'workflow-publish': false,
  'workflow-import-export': false,
  'workflows-can-use-auto-flows': false,
  'checkout-express-form': false,
  'workflows-maintenance-mode': {
    'maintenance-mode': false,
    start: '',
    end: '',
  },
  'save-templates': false,
  'pci-app-publish': false,
  'network-tokenization-status': 'inactive',
  'uplift-ai-status': 'inactive',
  'reconciliation-decimal-places': false,
  'monitor-event-details-page': false,
  'show-read-only-user-role-in-invite-modal': false,
} as const;

type FeatureFlags = typeof defaultFeatureFlags;
export type FeatureFlagsTransformed = KeysToCamelCase<
  MakeUnionWithUndefined<FeatureFlags>
>;
