import { useState } from 'react';
import ForgotPasswordCard from '../components/forgot_password/cards/ForgotPasswordCard';
import { usePostForgotPassword } from '../api/password';

export function ForgotPassword() {
  const [usedEmail, setUsedEmail] = useState('');
  const { trigger, data, error, isMutating } = usePostForgotPassword();

  return (
    <ForgotPasswordCard
      status={
        isMutating ? 'loading' : error ? 'error' : data ? 'success' : 'pending'
      }
      formProps={{
        onSubmit: (values) => {
          trigger({ userEmail: values.username });
          setUsedEmail(values.username);
        },
        externalError: error
          ? 'Something went wrong, please try again'
          : undefined,
        loading: isMutating,
      }}
      usedEmail={usedEmail}
    />
  );
}
