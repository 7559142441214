import {
  FORGOT_PASSWORD_KEY,
  postForgotPassword,
  RESET_PASSWORD_KEY,
  postResetPassword,
  FORGOT_PASSWORD_TOKEN_KEY,
  getForgotPasswordToken,
} from 'SRC/fetch/api/auth/forgotPassword';
import useSWRMutation from 'swr/mutation';
import useSWRImmutable from 'swr/immutable';

export const usePostForgotPassword = () =>
  useSWRMutation(FORGOT_PASSWORD_KEY, postForgotPassword, {});

export const usePostResetPassword = () =>
  useSWRMutation(RESET_PASSWORD_KEY, postResetPassword, {});

export const useQueryForgotPasswordToken = (token: string) =>
  useSWRImmutable([FORGOT_PASSWORD_TOKEN_KEY, token], ([_key, t]) =>
    getForgotPasswordToken(t),
  );
